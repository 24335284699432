<mdb-navbar #navbar SideClass="navbar navbar-expand-lg navbar-dark indigo-color-dark darken-2 justify-content-between fixed-top" [containerInside]="true">

  <mdb-navbar-brand>
      <a class="navbar-brand ml-10" href="https://clubb2b.com.br">
          <img src="https://clubb2b.com.br/images/main/clubb2b.png" height="40" alt="clubb2b" 
          mdbTooltip="ClubB2B, sua StartUp de Negócios B2B. Clique aqui para acessar o site.">
      </a>
  </mdb-navbar-brand>

  <links>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" mdbTooltip="Vai para página da ClubB2B">
        <a class="nav-link waves-light" href="https://clubb2b.com.br" mdbWavesEffect>Visite á ClubB2B<span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </links>

</mdb-navbar>

<div class="container full-page-container bg-img">
  <div class="row h-100 d-md-flex justify-content-center align-items-center overflow-auto">
    <div class="col-md-8 text-center pt-1 fadeIn animated">
      <h1 *ngIf="innerWidth > 414" class="text-dark app-heading mb-4" mdbTooltip="Tudo que afeta a uma pessoa, afeta a todos na comunidade.">
        <a class="font-weight-bold yellow-text sombra-dark">Alô</a>
        <a class="font-weight-bold sombra-dark" style="color: #0078e9;">Comunidade</a>
      </h1>
      <div class="pt-5">
        <h1 *ngIf="innerWidth <= 414" class="mb-2 pt-3" mdbTooltip="Tudo que afeta a uma pessoa, afeta a todos em uma comunidade.">
          <a class="display-5 font-weight-bold yellow-text sombra-dark">Alô</a>
          <a class="display-5 font-weight-bold sombra-dark" style="color: #0078e9;">Comunidade</a>
        </h1>
      </div>      
      <h2 *ngIf="innerWidth > 414" class="app-heading mb-4 sombra-dark" mdbTooltip="Agora você poderá interagir com sua comunidade local, com apenas alguns clicks.">  
        <a class="font-weight-bold sombra-dark" style="color: #b3031b;">A Rede Social das Comunidades</a>
      </h2>
      <h2 *ngIf="innerWidth <= 414" class="display-5 mb-4 sombra-dark" mdbTooltip="Agora você poderá interagir com sua comunidade local, com apenas alguns clicks.">
        <a class="font-weight-bold sombra-dark" style="color: #b3031b;">A Rede Social das Comunidades</a> 
      </h2>      
      <!-- size="lg" class="danger-color-dark white-text font-weight-bold mb-2" -->
      <h4 class="subtext-header white-text text-justify mt-2 mb-4">
        <p class="font-weight-bold text-center sombra-dark pt-4">
          Em breve estaremos online para te conectar nesse mundo comunitário!
        </p>
        <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra-dark">
          SITE EM CONSTRUÇÃO
        </h1>
        <h3 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-5 pt-5 pb-md-2 pb-2 sombra-dark">
          SITE EM CONSTRUÇÃO
        </h3>
        <div *ngIf="innerWidth > 414" class="timer sombra-dark">
          <h4 class="font-weight-bold red-text"> Contagem Regressiva </h4>
          <span id="days" class="font-weight-bold teal-text"> {{daysToDday}} </span>
          <a class="font-weight-bold">Dia(s)</a>
          <span id="hours" class="font-weight-bold teal-text"> {{hoursToDday}} </span>
          <a class="font-weight-bold sombra-dark">Hs</a> 
          <span id="minutes" class="font-weight-bold teal-text"> {{minutesToDday}} </span>
          <a class="font-weight-bold sombra-dark">Min</a>
          <span id="seconds" class="font-weight-bold teal-text"> {{secondsToDday}} </span>
          <a class="font-weight-bold">Seg</a>
        </div>
        <div *ngIf="innerWidth <= 414" class="timer-mobile sombra-dark">
          <h4 class="font-weight-bold red-text"> Contagem Regressiva </h4>
          <span id="days" class="font-weight-bold teal-text"> {{daysToDday}} </span>
          <a class="font-weight-bold">Dia(s)</a>
          <span id="hours" class="font-weight-bold teal-text"> {{hoursToDday}} </span>
          <a class="font-weight-bold">Hs</a> 
          <span id="minutes" class="font-weight-bold teal-text"> {{minutesToDday}} </span>
          <a class="font-weight-bold">Min</a>
          <span id="seconds" class="font-weight-bold teal-text"> {{secondsToDday}} </span>
          <a class="font-weight-bold">Seg</a>
        </div>
      </h4>
    </div>
  </div>
</div>

<footer class="footer-copyright text-center text-warning font-weight-bold py-3 info-color-dark darken-2 fixed-bottom sombra-dark">
  Copyright 2018
  <a class="white-text font-weight-bold">&copy;</a> - 
  <a class="text-white font-weight-bold" href="https://clubb2b.com.br/" mdbTooltip="ClubB2B, sua Starup de Negócios. Clique aqui para acessar o site."> 
    <a class="teal-text font-weight-bold"> Club</a>
    <a class="yellow-text font-weight-bold">B</a>
    <a class="blue-text font-weight-bold">2</a>
    <a class="white-text font-weight-bold">B</a>
    <a class="teal-text font-weight-bold"> Group</a>    
  </a>
</footer>

